import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import styles from "../styles/history.module.css"
import BackPageArrow from "../images/backpagearrow.svg"
import { Link } from "gatsby"
import Line from "../images/linea.svg"
import Arrow from "../images/logos/arrow.svg"
import * as Scroll from "react-scroll"
let LinkTo = Scroll.Link

function HistoryTop({ data }) {
  const [active, setActive] = useState(false)
  return (
    <>
      <BackgroundImage
        fluid={data.flag.childImageSharp.fluid}
        className={styles.top}
      >
        <div className={styles.blackLayout}></div>
        <div className={styles.backPage}>
          <img alt="" src={BackPageArrow} />
          <div className={styles.backPage}>
            <img
              alt=""
              src={BackPageArrow}
              id={active ? styles.backArrow : styles.backArrowHidden}
            />
            <Link
              onMouseLeave={() => setActive(!active)}
              onMouseOver={() => setActive(!active)}
              to="/"
              style={{ textDecoration: "none", color: "white" }}
            >
              VOLVER
            </Link>
          </div>
        </div>
        <div className={`${styles.textContainer}`}>
          <div className={`${styles.topText} ${styles.slideTextLeft}`}>
            <h1>
              HECHA <br></br>CON CARIÑO
            </h1>
            <img alt="" src={Line} />
            <p>
              Somos una cerveza de acá,hecha por gente de acá, con ingredientes
              de acá y para gente de acá. Somos la cerveza que tomás vos y la
              que tomaba tu viejo. Somos Quilmes, la cerveza argentina.
            </p>
          </div>

          <div id={styles.arrowCircle}>
            <LinkTo to="sectionTwo" smooth={true}>
              <div id={styles.circleFixed}>
                <img alt="" id={styles.arrowTop} src={Arrow} />
              </div>
            </LinkTo>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default HistoryTop
