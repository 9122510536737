import React, { useState } from "react"
import styles from "../styles/history.module.css"
import OldCars from "../images/oldCars.png"
import BarrelsMobile from "../images/3.png"
import Barrels from "../images/barrels.png"
import Dot from "../images/lineaDeco.svg"
import Qletter from "../images/8.png"
import QFactory from "../images/factory.png"
import { useMediaQuery } from "react-responsive"
import { useScrollData } from "scroll-data-hook"
function NuestroComienzo() {
  const {
    scrolling,
    time,
    speed,
    direction,
    position,
    relativeDistance,
    totalDistance,
  } = useScrollData({
    onScrollStart: () => {},
  })

  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const [move, setMove] = useState(false)
  return (
    <>
      <section id="sectionTwo" className={styles.sectionTwo}>
        <img alt="autos antiguos" id={styles.cars} src={OldCars} />
        <div className={styles.contenedorImagenes}>
          {mobile ? (
            <img
              alt="barriles de cerveza"
              id={styles.barrels}
              src={BarrelsMobile}
            />
          ) : (
            <img
              alt="barriles de cerveza"
              onMouseOver={() => {
                setMove(!move)
              }}
              onMouseLeave={() => {
                setMove(!move)
              }}
              id={!move ? styles.barrels : styles.barrelsMove}
              src={Barrels}
            />
          )}
          <img
            alt="cartel de cerveza Quilmes"
            onMouseOver={() => {
              setMove(!move)
            }}
            onMouseLeave={() => {
              setMove(!move)
            }}
            id={!move ? styles.letter : styles.letterMove}
            src={Qletter}
          />
        </div>
        <div className={styles.comienzoContainer}>
          <img alt="" id={styles.dot} src={Dot} />
          <div className={`${styles.sectionTwoTitle}`}>
            <h1 id={position.y > 318 ? `${styles.nuestroComienzo}` : null}>
              NUESTROS COMIENZOS
            </h1>
            <h5 id={position.y > 318 ? `${styles.otto}` : null}>
              Otto Bemberg 1890
            </h5>
          </div>
          <p
            className={
              position.y > 318
                ? `${styles.sectionTwoTextAnimation}`
                : `${styles.sectionTwoText}`
            }
          >
            Todo comenzó en 1890 en la localidad de Quilmes. Ahí, Otto Bemberg
            trabajó arduamente para elaborar una cerveza que estuviera a la
            altura de su tradición alemana y luego de dos años, esa idea con la
            que llegó de Europa, no sólo se convertiría en realidad, sino que,
            adaptada al paladar argentino con el tiempo se convertiría en la
            cerveza más importante del país.
          </p>
          <img
            alt="fábrica antigua de cerveza Quilmes"
            id={styles.factory}
            src={QFactory}
          />
        </div>
      </section>
    </>
  )
}

export default NuestroComienzo
