import React, { Component } from "react"
import Slider from "react-slick"
import campo from "../images/campo2.svg"
import fermentacion from "../images/fermentacion.svg"
import germinacion from "../images/germinacion.svg"
import salud from "../images/salud.svg"
import molienda from "../images/molienda.svg"
import cocimiento from "../images/cocimiento.svg"
import embotellado from "../images/embotellado.svg"
import styles from "../styles/history.module.css"

const elaboracion = [
  {
    title1: "Campo",
    text: "",

    img: campo,
    id: "campo",
  },
  {
    title1: "Remojo  ",
    title2: "Germinación",
    title3: "Secado",
    text:
      "Luego de obtener los granos de cebada, estos son llevados a nuestras malterías donde comienza el proceso.",
    img: germinacion,
    id: "germinacion",
  },
  {
    title1: "Molienda",
    title2: "Maceración",
    text:
      "El grano de cebada es partido para aprovechar todos sus nutrientes. Se lo mezcla con agua para obtener el mosto, un líquido espeso y de sabor dulce. Luego de agregar el agua, agregamos el maíz al proceso.",
    img: molienda,
    id: "molienda",
  },
  {
    title1: "Cocimiento",
    title2: "Enfriado",
    text:
      "Aqui hervimos el mosto a más de 100° y agregamos otro ingrediente: el lúpulo, luego creamos las condiciones necesarias para comenzar la fermentación y maduración.",
    img: cocimiento,
    id: "cocimiento",
  },
  {
    title1: "Fermentación",
    title2: "Maduración",
    text:
      "La fermentación es un proceso que dura 14 días en el agregamos la levadura tipo lager, que es la encargada de generar la carbonatación y el alcohol natural en el mosto. Luego se deja al mosto estabilizarse, madurar y reposar.",
    img: fermentacion,
    id: "fermentacion",
  },
  {
    title1: "Filtrado",
    title2: "Embotellado",
    text:
      "El filtrado nos permite obtener el color brillante y dorado de nuestra Quilmes, para luego pasar a la etapa en la cual envasamos y pasteurizamos nuestra cerveza. Este proceso es el que nos permite optimizar su conservación.",
    img: embotellado,
    id: "filtrado",
  },
  {
    title1: "Salud!",
    text: "",
    img: salud,
    id: "salud",
  },
]
let active = true
class CustomSlide extends Component {
  render() {
    const { title, img, ...props } = this.props
    return (
      <div className={styles.elaboracionImg} {...props}>
        <img src={img} />
      </div>
    )
  }
}

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title1: elaboracion[0].title1,
      title2: "",
      title3: "",
      text: elaboracion[0].text,
    }

    this.change = this.change.bind(this)
  }
  change(index) {
    this.setState({
      text: elaboracion[index].text,
      title1: elaboracion[index].title1,
      title2: elaboracion[index].title2,
      title3: elaboracion[index].title3,
    })
  }
  render() {
    const settings = {
      dots: false,
      dotsClass: `slick-dots ${styles.dotsClass}`,
      smooth: true,
      infinite: false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (oldindex, newIndex) => {
        active = !active
        this.change(newIndex)
      },
    }
    return (
      <div className={styles.sliderContainer}>
        <h2 className={styles.elaboracionTitle} style={{marginBottom:"-20%"}}>CÓMO ELABORAMOS</h2>

        <Slider {...settings}>
          <CustomSlide
            className={
              !active ? `${styles.campo}` : `${styles.active} ${styles.campo}`
            }
            img={elaboracion[0].img}
          />
          <CustomSlide
            className={
              !active
                ? `${styles.active} ${styles.germinacion}`
                : `${styles.germinacion}`
            }
            img={elaboracion[1].img}
          />
          <CustomSlide
            className={
              !active
                ? `${styles.molienda}`
                : `${styles.molienda} ${styles.active}`
            }
            img={elaboracion[2].img}
          />
          <CustomSlide
            className={
              !active
                ? `${styles.cocimiento} ${styles.active}`
                : `${styles.cocimiento}`
            }
            img={elaboracion[3].img}
          />
          <CustomSlide
            className={
              !active
                ? `${styles.fermentacion}`
                : `${styles.fermentacion} ${styles.active}`
            }
            img={elaboracion[4].img}
          />
          <CustomSlide
            className={
              !active
                ? `${styles.embotellado1} ${styles.active}`
                : `${styles.embotellado1}`
            }
            img={elaboracion[5].img}
          />
          <CustomSlide
            className={
              !active
                ? `${styles.saludActive}`
                : ` ${styles.active} ${styles.salud} `
            }
            img={elaboracion[6].img}
          />
        </Slider>

        <div className={styles.titleContainer}>
          <h3
            className={
              this.state.title1 === "Salud!"
                ? styles.processTitle1
                : styles.processTitle
            }
          >
            {this.state.title1.toUpperCase()}
          </h3>
          <h3 className={styles.processTitle}>
            {this.state.title2 ? this.state.title2.toUpperCase() : null}
          </h3>
          <h3 className={styles.processTitle}>
            {this.state.title3 ? this.state.title3.toUpperCase() : null}
          </h3>
        </div>
        <p className={styles.elaboracionText}>{this.state.text}</p>
      </div>
    )
  }
}
